// detect touch events

const $cursor = document.getElementById('cursor');

document.addEventListener('mousemove', e => {
	$cursor.setAttribute("style", "top: "+(e.pageY - 10)+"px; left: "+(e.pageX - 10)+"px;")
})


let pageState = 1;

// Get all links and buttons on the page

let links = Array.from(document.querySelectorAll('a'));
let buttons = Array.from(document.querySelectorAll('button'));

let interactables = links.concat(buttons);

// for each page 
interactables.forEach( interactable =>{

	interactable.addEventListener('click', (e) =>{
	e.stopPropagation()
	});
	interactable.addEventListener('mouseover', () =>{
		$cursor.classList.add('interact');
		$cursor.classList.remove('cross');
	});
	interactable.addEventListener('mouseleave', () =>{
		$cursor.classList.remove('interact');
		if (pageState == 2){
			$cursor.classList.add('cross');
		}
	});

})



const $transitionButton =   document.getElementById('go-2');
const $page2 =  document.getElementById('section-2');
$transitionButton.addEventListener('click', () => {

	$page2.classList.remove('fade-out');
	$page2.classList.add('fade-in');
	$cursor.classList.add('cross');
	pageState = 2;

});

$page2.addEventListener('click', (e) => {
	console.log(e.currentTarget)
	e.stopPropagation();
	if (e.currentTarget !== this){
		$page2.classList.remove('fade-in');
		$page2.classList.add('fade-out');
		$cursor.classList.remove('cross');
        pageState = 1;
    }

});